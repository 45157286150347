<template>
  <s-crud
    title="Asiento SCIRE SAP"
    :config="config"
    @save="save($event)"
    @load="loadSAP()"
    load
    remove
    edit
    sortable
    :filter="config.filter"
    ref="crud"
  >
    <template slot="filter">
      <v-container grid-list-xs>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-switch
              label="Solo asientos pendientes de migrar"
              v-model="config.filter.JdtStatus"
              dense
              class="ml-5 mt-1
              
              "
              hide-details
              :false-value="1"
              :true-value="0"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="processingSAP" :persistent="messageProcessingSAP.length == 0" width="400">
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessingSAP.length == 0">
            <div class="pt-2">
              Por favor espere
            </div>
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar operacion en SAP <br />
              {{ messageProcessingSAP }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template slot-scope="props">
      <v-container class="pb-0">
        <v-row justify="center">
          <v-col cols="12" class="s-col-form">
            <s-text v-model="props.item.Memo" label="Descripción de Asiento" autofocus />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:JdtStatus="{ row }">
      <v-chip x-small dense :color="row.JdtStatus == 1 ? 'green' : 'orange'" dark>
        {{ row.JdtStatus == 1 ? "Migrado" : "Pendiente" }}
      </v-chip>
    </template> 
  </s-crud>
</template>

<script>
import _sScireSAP from "@/services/HumanResource/ScireSAPService";

export default {
  data() {
    return {
      processingSAP: false,
      messageProcessingSAP: "",
      config: {
        filter: {
          JdtStatus: 1,
        },
        model: {
          Memo: "string",
          JdtNum: "ID",
          TransID: "int",
          JdtStatus: "int",
          SecStatus: "status",
          ReferenceDate: "date",
          Debit: "currency",
          Credit: "currency",
          Difference: "currency",
        },
        service: _sScireSAP,
        headers: [
          {
            align: "end",
            text: "ID",
            value: "JdtNum",
            sortable: false,
          },
          {
            text: "Fecha",
            value: "ReferenceDate",
          },
          {
            width: "60%",
            text: "Asiento",
            value: "Memo",
            sortable: false,
          },
          {
            align: "end",
            text: "Debito",
            value: "Debit",
            sortable: false,
          },
          {
            align: "end",
            text: "Credito",
            value: "Credit",
            sortable: false,
          }, 
          {
            align: "end",
            text: "Diferencia",
            value: "Difference",
            sortable: false,
          }, 
          {
            align: "end",
            text: "SAP ID",
            value: "TransID",
            sortable: false,
          },
          {
            align: "center",
            text: "Estado",
            value: "JdtStatus",
          },
        ],
      },
    };
  },
  methods: {
    save(item) {
      if (item.Memo.length == 0) {
        this.$fun.alert("Registre Descripción de asiento", "warning");
        return;
      }
      item.save();
    },
    loadSAP() {
      this.processingSAP = true;
      this.messageProcessingSAP = "";
      _sScireSAP.loadsap(this.$fun.getUserID()).then(
        (r) => {
          this.$fun.alert("Asientos cargados correctamente", "success");
          this.$refs.crud.refresh();
          this.processingSAP = false;
          this.messageProcessingSAP = "";
        },
        (e) => {
          this.messageProcessingSAP = e.response.data.Message;
        }
      );
    },
  },
};
</script>
